// Copyright 2024 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { createContext } from 'react';
import { ReactNode, useState } from 'react';

import { SingletonStore } from './singleton_store';

export const SingletonStoreCtx = createContext<
  SingletonStore<unknown> | undefined
>(undefined);

export interface SingletonStoreProviderProps {
  readonly children: ReactNode;
}

export function SingletonStoreProvider({
  children,
}: SingletonStoreProviderProps) {
  const [value] = useState(() => new SingletonStore());
  return (
    <SingletonStoreCtx.Provider value={value}>
      {children}
    </SingletonStoreCtx.Provider>
  );
}
