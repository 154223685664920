// Copyright 2023 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Feedback } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { genFeedbackUrl } from '@/common/tools/utils';

export function FeedbackButton() {
  return (
    <IconButton
      sx={{ color: 'white' }}
      onClick={() => window.open(genFeedbackUrl())}
      role="button"
      aria-label="Send feedback"
      title="Send feedback"
    >
      <Feedback />
    </IconButton>
  );
}
