// Copyright 2024 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { useContext } from 'react';

import {
  HasNewReleaseCtx,
  MarkReleaseNotesAsReadCtx,
  ReleaseNotesCtx,
} from './context';

export function useReleaseNotes() {
  const ctx = useContext(ReleaseNotesCtx);
  if (ctx === undefined) {
    throw new Error(
      'useReleaseNotes can only be used in a ReleaseNotesProvider',
    );
  }
  return ctx;
}
export function useHasNewRelease() {
  const ctx = useContext(HasNewReleaseCtx);
  if (ctx === undefined) {
    throw new Error(
      'useHasNewRelease can only be used in a ReleaseNotesProvider',
    );
  }
  return ctx;
}

export function useMarkReleaseNotesRead() {
  const ctx = useContext(MarkReleaseNotesAsReadCtx);
  if (ctx === undefined) {
    throw new Error(
      'useMarkReleaseNotesRead can only be used in a ReleaseNotesProvider',
    );
  }
  return ctx;
}
